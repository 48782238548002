import Image from "next/image";
import Link from "next/link";

import CourseDetails from "../../data/course-details/courseData.json";
import { useEffect, useState } from "react";
import { BaseApiService } from "@/utils/BaseApiService";
import { isNotEmpty } from "@/utils/Utils";

const Card = ({ start, end, col, mt, isDesc, isUser }) => {
  const [courses, setCourses] = useState([]);

  const fetchCourses = async () => {
    let searchParameters = { searchTerm: "", offset: 0, limit: 12, featured: true };
    try {
      const response = await new BaseApiService("/courses").getRequestWithJsonResponse(
        searchParameters
      );
      // console.log("Course Response:", response);
      if (response && response.records) {
        setCourses(response.records);
        // console.log("Courses set:", response.records); // Log the state update
      } else {
        console.error("Unexpected response structure:", response);
      }
    } catch (error) {
      console.error("fetchCourses:fetchDataFromServer>>>>", error);
    }
  };

  useEffect(() => {
    fetchCourses();
  }, []);

  return (
    <>
      {courses.length === 0 ? (
        <p>No courses available.</p>
      ) : (
        courses.slice(start, end).map((data, index) => (
          <div
            className={`${col} ${mt}`}
            // data-sal-delay="150"
            // data-sal="slide-up"
            //  data-sal-duration="800"
            key={index}
          >
            <div className="rbt-card variation-01 rbt-hover">
              <div className="rbt-card-img">
                <Link legacyBehavior href={data.id ? `/course-details/${data.id}` : "#"}>
                  <a>
                    <img
                      src={
                        isNotEmpty(data.coverImageUrl)
                          ? data.coverImageUrl
                          : "https://res.cloudinary.com/revival-gateway/image/upload/v1687342256/courses/lessons/297e018288dd4b820188dd6c19400005.jpg"
                      }
                      alt="Card image"
                      style={{ width: 355, height: 200 }}
                      width={355}
                      height={244}
                    />
                    {data.cost > 0 ? (
                      <div className="rbt-badge-3 bg-white">
                        <span>-{data.cost}%</span>
                        <span>Off</span>
                      </div>
                    ) : (
                      ""
                    )}
                  </a>
                </Link>
              </div>
              <div className="rbt-card-body">
                <div className="rbt-card-top">
                  <div className="rbt-review">
                    <div className="rating">
                      {[...Array(5)].map((_, index) => {
                        const ratingValue = index + 1;
                        return (
                          <i
                            key={index}
                            className={
                              ratingValue <= Math.floor(data?.averageRating)
                                ? "fas fa-star"
                                : ratingValue === Math.ceil(data?.averageRating) && !Number.isInteger(data?.averageRating)
                                  ? "fas fa-star-half-alt"
                                  : "far fa-star"
                            }
                          ></i>
                        );
                      })}
                    </div>
                    <span className="rating-count">({data.ratingsCount} Reviews)</span>
                  </div>
                  <div className="rbt-bookmark-btn">
                    <Link legacyBehavior href="#">
                      <a className="rbt-round-btn" title="Bookmark">
                        <i className="feather-bookmark"></i>
                      </a>
                    </Link>
                  </div>
                </div>

                <h4 className="rbt-card-title">
                  <Link legacyBehavior href={data.id ? `/course-details/${data.id}` : "#"}>
                    <a>{data.title || "Untitled Course"}</a>
                  </Link>
                </h4>

                <ul className="rbt-meta">
                  <li>
                    <i className="feather-book"></i>
                    {data.numberOfLessons} Lessons
                  </li>
                  <li>
                    <i className="feather-users"></i>
                    {data.numberOfLessons} Students
                  </li>
                </ul>
                {isDesc ? <p className="rbt-card-text">{data.description}</p> : ""}
                {isUser ? (
                  <div className="rbt-author-meta mb--10">
                    <div className="rbt-avater">
                      <Link
                        legacyBehavior
                        href={
                          data.instructor && data.instructor.id
                            ? `/profile/${data.instructor.id}`
                            : "#"
                        }
                      >
                        <a>
                          <img
                            src={
                              data.instructor && data.instructor.imageUrl
                                ? data.instructor.imageUrl
                                : "https://res.cloudinary.com/revival-gateway/image/upload/v1687342256/courses/lessons/297e018288dd4b820188dd6c19400005.jpg"
                            }
                            alt={
                              data.instructor && data.instructor.fullName
                                ? data.instructor.fullName
                                : "Instructor"
                            }
                            width={355}
                            height={244}
                          />
                        </a>
                      </Link>
                    </div>
                    <div className="rbt-author-info">
                      By{" "}
                      <Link
                        legacyBehavior
                        href={
                          data.instructor && data.instructor.id
                            ? `/profile/${data.instructor.id}`
                            : "#"
                        }
                      >
                        <a>{data.instructor && data.instructor.fullName}</a>
                      </Link>{" "}
                      In{" "}
                      <Link legacyBehavior href="#">
                        <a>{data.category && data.category.name}</a>
                      </Link>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="rbt-card-bottom">

                  {
                    data.paid ?
                      <div className="rbt-price">
                        <span className="current-price">${data.price}</span>
                        <span className="off-price">${data.discountedPrice}</span>
                      </div> :
                      <div className="rbt-price">
                        <span className="current-price">${0}</span>
                        <span className="off-price">${0}</span>
                      </div>
                  }

                  {data.button ? (
                    <Link legacyBehavior href={data.id ? `/course-details/${data.id}` : "#"}>
                      <a className="rbt-btn-link left-icon">
                        <i className="feather-shopping-cart"></i> Add To Cart
                      </a>
                    </Link>
                  ) : (
                    <Link legacyBehavior href={data.id ? `/course-details/${data.id}` : "#"}>
                      <a className="rbt-btn-link">
                        Learn More<i className="feather-arrow-right"></i>
                      </a>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </>
  );
};

export default Card;
